<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="main-content">
				<div class="header bg-white border-bottom">
					<div class="container-fluid">
						<div class="header-body border-0">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Service Overview</h6>
									<h1 class="header-title">
										{{ service.name }}
									</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<router-link
						to="/on-trip-services/list"
						class="btn btn-light mb-4 btn-sm"
						>
						Go back
					</router-link>
					<div class="row">
						<div class="col-12 col-xl-6">
							<div class="card">
								<div class="card-header justify-content-between">
									<h4 class="card-header-title">Service Information</h4>
									<div class="dropdown">
										<button
											class="btn btn-danger dropdown-toggle"
											type="button"
											id="dropdownMenuButton"
											data-toggle="dropdown"
											aria-expanded="false"
											>
											Actions
										</button>
										<div
											class="dropdown-menu"
											aria-labelledby="dropdownMenuButton"
											>
											<a
												class="dropdown-item text-danger"
												href="#"
												@click.prevent="deleteService"
												>Delete Service</a
												>
											<a
												class="dropdown-item"
												:class="
													isServiceActive ? 'text-danger' : 'text-success'
												"
												href="#"
												@click.prevent="suspendOrUnsuspendService"
												>{{
													isServiceActive ? 'Suspend' : 'Unsuspend'
												}}
												Service</a
												>
										</div>
									</div>
								</div>
								<div class="card-body p-2">
									<div class="list-group list-group-flush my-4">
										<div class="list-group-item">
											<div class="row align-items-center">
												<div class="col">
													<span class="text-muted text-uppercase">Name</span>
												</div>
												<div class="col-auto">
													{{ service.name }}
												</div>
											</div>
										</div>
										<div class="list-group-item">
											<div class="row align-items-center">
												<div class="col">
													<span class="text-muted text-uppercase">Price</span>
												</div>
												<div class="col-auto">
													<span class=""
														>&#x20A6;{{ service.price | money }}</span
														>
												</div>
											</div>
										</div>
										<div class="list-group-item">
											<div class="row align-items-center">
												<div class="col">
													<span class="text-muted text-uppercase"
														>Quantity</span
														>
												</div>
												<div class="col-auto">
													<span class="">{{ service.quantity }}</span>
												</div>
											</div>
										</div>
										<div class="list-group-item">
											<div class="row align-items-center">
												<div class="col">
													<span class="text-muted text-uppercase">Status</span>
												</div>
												<div class="col-auto">
													<span
														:class="
															isServiceActive ? 'text-success' : 'text-danger'
														"
														>{{ isServiceActive ? 'Active' : 'Inactive' }}</span
														>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-xl-6">
							<div class="card">
								<div class="card-header">
									<div class="row align-items-center">
										<div class="col">
											<h4 class="card-header-title">Update Service</h4>
										</div>
									</div>
								</div>
								<div class="card-body">
									<div
										class="alert alert-danger alert-dismissible fade show"
										role="alert"
										v-if="errorMessage"
										>
										{{ errorMessage }}
									</div>
									<form @submit.prevent="updateService">
										<div class="form-group row">
											<div class="col-12">
												<label class="form-label">Name</label>
												<input
													v-model.trim="updateServiceModel.name"
													required
													type="text"
													class="form-control"
													/>
											</div>
										</div>
										<div class="form-group row">
											<div class="col-12 col-md-6">
												<label class="form-label">Price</label>
												<input
													v-model.trim="updateServiceModel.price"
													required
													type="number"
													class="form-control"
													/>
											</div>
											<div class="col-12 col-md-6">
												<label class="form-label">Quantity</label>
												<input
													v-model.trim="updateServiceModel.quantity"
													required
													type="number"
													class="form-control"
													/>
											</div>
										</div>
										<div class="form-group row">
											<div class="col-12 col-md-6">
												<label class="form-label">Status</label>
												<select
													v-model="updateServiceModel.status"
													class="form-control form-select"
													>
													<option value="1">Active</option>
													<option value="0">Disabled</option>
												</select>
											</div>
											<div class="col-12 col-md-6">
												<label class="form-label">Product/Service Image</label>
												<input
													type="file"
													accept="image/*"
													class="form-control"
													@change="prepareImageUpload"
													/>
											</div>
										</div>
										<button
											type="submit"
											class="btn btn-primary mt-4"
											:disabled="processing"
											>
											{{ processing ? 'Processing...' : 'Update Service' }}
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  props: {
    serviceId: {
      required: true,
      type: [Number, String]
    }
  },
  components: {},
  data () {
    return {
      service: null,
      loading: true,
      errorLoading: false,
      updateServiceModel: null,
      processing: false,
      errorMessage: '',
      showChangeDriver: false
    }
  },
  created () {
    this.init()
  },
  computed: {
    isServiceActive () {
      if (!this.service) {
        return false
      }

      return parseInt(this.service.status) === 1
    }
  },
  methods: {
    async init () {
      this.loading = true
      this.errorLoading = false

      this.fetchService()
        .then(() => {})
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchService () {
      return this.axios.get(`/v1/services/${this.serviceId}`).then((res) => {
        this.service = res.data

        this.updateServiceModel = { ...this.service }
      })
    },
    async deleteService () {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to delete this service?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .delete(`/v1/services/${this.serviceId}`)
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Service has been deleted successfully',
            showCloseButton: true
          })

          this.$router.push('/on-trip-services/list')
        }
      })
    },
    async suspendOrUnsuspendService () {
      const isSuspendAction = this.isServiceActive

      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${
          this.isServiceActive ? 'suspend' : 'unsuspend'
        } this service?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/services/${this.serviceId}`, {
              status: this.isServiceActive ? 0 : 1
            })
            .then((res) => {
              this.service = { ...res.data }
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `Service has been ${
              isSuspendAction ? 'suspended' : 'unsuspended'
            } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    updateService () {
      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/services/${this.service.id}`, {
          name: this.updateServiceModel.name,
          price: this.updateServiceModel.price,
          status: parseInt(this.updateServiceModel.status, 10),
          quantity: this.updateServiceModel.quantity,
          image: this.updateServiceModel.image
            ? this.updateServiceModel.image
            : 'NULL'
        })
        .then(() => {
          this.service = { ...this.updateServiceModel }

          this.$swal({
            icon: 'success',
            title: 'Service Updated',
            text: 'Service information has been updated successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    },
    prepareImageUpload (event) {
      this.updateServiceModel.image = event.target.value
    }
  }
}
</script>

<style scoped>
</style>
